*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
body{
    background: #2c3e50;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #3498db, #2c3e50);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #3498db, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.content{
    position:relative;
    height:100vh;
}
.contenu{
    text-align: center;
    color:white;
    max-width: 768px;
    margin:auto;
}
.contenu > div > img{
    width:30px;
    height:30px;
    margin-right:20px;
}
.contenu > div:nth-child(1){
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    padding-top:30px;
}
.contenu > h4{
    padding-top:10px;
}
.contenu > h2{
    margin-top:30px;
    margin-bottom:30px;
    background: lightslategrey;
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-size:1.2rem;
}
.contenu > table:nth-child(4) > tbody > tr > td:nth-child(2)::before{
    content:"\002620";
    color:white;
    padding-right:5px;
}
.contenu > table:nth-child(4) > tbody > tr > td,.contenu > table:nth-child(6) > tbody > tr > td{
    margin-left:auto;
    color:white;
    font-size:1.4em;
    padding:8px;
}
.contenu > table > td:nth-child(1){
    margin-left:auto;
    color:white;
    font-size:1.5rem;
    text-decoration: underline;
    padding-right:25px;
}
table{
    margin:auto;
    width:90%;
    border:2px solid white;
    margin-bottom:20px;
}
select{
    padding: 7px;
    width: 56%;
    background: transparent;
    color: white;
    font-weight: bold;
    border: 2px solid white;
    margin-left:15px;
}
option{
    background: white;
    color: black;
}
@media screen and (max-width: 430px){
    select{
        margin-top:10px;
    }
}

/* Loader */
.lds-roller {
    display: inline-block;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 80px;
    height: 80px;
}
.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #fff;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
